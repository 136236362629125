<template>
  <v-container>
    <div class="text-h5 font-weight-bold mb-4">Relatórios</div>

    <TabNavigation
      class="mb-4"
      :tabs="_tabs"
      :default="tab"
      @change="handleTabs($event)"
    />

    <router-view />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";

export default {
  name: "Reports",

  components: {
    TabNavigation,
  },

  data() {
    return {
      tab: "QueueAttendance",
    };
  },

  created() {
    this.tab = this.$route.name;
  },

  computed: {
    ...mapState(["module"]),

    _tabs() {
      return [
        {
          label: "Atendimento",
          value: "QueueAttendance",
        },
        ...(this.module !== "attendant"
          ? [
              {
                label: "Tempo de Atendimento",
                value: "TimeAttendance",
              },
              {
                label: "Relatório de Satisfação",
                value: "Satisfaction",
              },
              {
                label: "Desistências",
                value: "Quit",
              },
              {
                label: "Tempo médio de espera",
                value: "QueueWaitingTime",
              },
            ]
          : []),
      ];
    },
  },

  methods: {
    handleTabs(event) {
      if (event.value === this.tab) return;

      this.tab = event.value;
      this.$router.push({ name: event.value });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
