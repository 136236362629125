<template>
  <v-navigation-drawer
    v-model="drawer"
    class="custom-drawer"
    width="18.75rem"
    color="gray2"
    height="100%"
    dark
    app
  >
    <div class="d-flex align-center px-4 py-2">
      <v-menu
        content-class="mt-2"
        min-width="12.5rem"
        :close-on-content-click="false"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            bordered
            offset-x="30"
            offset-y="8"
            :color="_currentStatus"
            bottom
            dot
          >
            <v-avatar
              v-bind="attrs"
              v-on="on"
              class="mr-6"
              size="2.5rem"
              color="gray4"
              rounded
            >
              <v-img v-if="user.photo" :src="user.photo" />
              <v-icon v-else>mdi-account-outline</v-icon>
            </v-avatar>
          </v-badge>
        </template>

        <v-list class="pt-0">
          <v-list-item>
            <v-list-item-title>Definir status</v-list-item-title>
          </v-list-item>

          <v-divider />

          <v-list-item
            v-for="(item, i) in status"
            :key="i"
            :class="{ 'primary--text': user.status === item.value }"
            dense
            @click="changeStatus(item.value)"
          >
            <v-list-item-icon class="mr-2">
              <v-icon :color="item.color" x-small>mdi-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer />

      <v-btn class="custom-btn mr-2" small icon>
        <v-icon small @click="notificationBell">mdi-bell</v-icon>
      </v-btn>

      <v-menu
        content-class="mt-2"
        min-width="12.5rem"
        :close-on-content-click="false"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="custom-btn" small icon>
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="handleDialog()">
            <v-list-item-icon class="mr-2">
              <v-icon small>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Meu Perfil</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="_isAdmin">
            <v-switch
              class="mt-0 pt-0"
              max-width="1rem"
              max-height="1rem"
              v-model="adminStatus"
              @change="changeAdminStatus"
              :label="`Disponível	para atendimento`"
              hide-details
            ></v-switch>
          </v-list-item>
          <v-list-item @click="signOut()">
            <v-list-item-icon class="mr-2">
              <v-icon small>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <section class="d-flex flex-column pb-4">
      <v-divider class="gray1 mb-4" />

      <router-link
        v-for="(item, i) in _routes"
        v-slot="{ href, navigate, isActive }"
        v-show="item.modules.includes(module)"
        :to="item.path"
        :key="i"
        custom
      >
        <v-list-item
          class="px-4"
          :class="isActive ? 'gray3' : 'transparent'"
          :href="href"
          @click="navigate"
        >
          <v-list-item-avatar rounded>
            <v-icon v-if="item.icon">
              {{ item.icon }}
            </v-icon>

            <v-avatar v-else size="2rem" color="gray4" rounded />
          </v-list-item-avatar>

          <v-list-item-title class="gray5--text">
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </router-link>
    </section>

    <ProfileDialog ref="profileDialog" />
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ProfileDialog from "./partials/profileDialog/ProfileDialog.vue";
import store from "@/store";

export default {
  name: "NavDrawer",

  components: {
    ProfileDialog,
  },

  data() {
    return {
      drawer: true,
      adminStatus: false,
      status: [
        {
          color: "grey",
          value: "offline",
          label: "Invisível",
        },
        {
          color: "amber",
          value: "away",
          label: "Ausente",
        },
        {
          color: "red",
          value: "busy",
          label: "Ocupado",
        },
        {
          color: "green",
          value: "online",
          label: "Disponível",
        },
      ],
    };
  },

  computed: {
    ...mapState(["user", "module"]),
    ...mapGetters(["isAdminBusy"]),
    _currentStatus() {
      const status = this.user.status || "online";
      const result = this.status.find((el) => el.value === status);
      return result ? result.color : "green";
    },
    _isAdmin() {
      return this.user.type !== "attendant";
    },

    _routes() {
      return [
        {
          label: "Visão Geral",
          path: "/home",
          icon: "mdi-view-dashboard",
          modules: ["attendant", "coordinator", "director"],
        },
        {
          label: "Balcões",
          path: "/counters",
          icon: "mdi-view-day-outline",
          modules: ["coordinator", "director"],
        },
        {
          label: "Usuários",
          path: "/users",
          icon: "mdi-account-multiple-outline",
          modules: ["coordinator", "director"],
        },
        {
          label: "Relatórios",
          path: "/reports",
          icon: "mdi-chart-areaspline",
          modules: ["attendant", "coordinator", "director"],
        },
        {
          label: "Fila de Atendimento",
          path: "/attendance",
          icon: "mdi-chart-box",
          modules: ["attendant", "coordinator", "director"],
        },
      ];
    },
  },

  methods: {
    handleDrawer() {
      this.drawer = !this.drawer;
    },

    changeStatus(value) {
      this.$emit("change-status", value);
    },

    signOut() {
      this.$emit("sign-out");
    },
    handleDialog() {
      this.$refs.profileDialog.open();
    },
    notificationBell() {
      this.$emit("notification-bell");
    },
    changeAdminStatus(e) {
      store.dispatch("setAdminStatus", e);
    },
  },
  mounted() {
    this.adminStatus = this.isAdminBusy;
  },
};
</script>

<style src="./style.scss" lang="scss" />
