<template>
  <CustomDialog
    ref="customDialog"
    :title="_title"
    :subtitle="_subtitle"
    :footerButtons="_buttons"
    :loading="loading"
    @submit="submit()"
  >
    <v-form v-model="valid" ref="form" class="mt-4" :disabled="loading">
      <v-select
        v-model="form.counter"
        item-text="label"
        item-value="value"
        label="Balcão"
        :items="counters"
        :rules="_rules"
        single-line
        outlined
      />

      <v-card
        class="pa-6 rounded-xl"
        color="grey lighten-3"
        flat
        @click="selectFiles()"
      >
        <div
          class="d-flex text-center justify-center align-center text-body-1 font-weight-regular"
        >
          <div v-if="!form.file">Selecionar arquivos*</div>

          <div v-else>
            Importar:
            <strong>
              {{ form.file.name }}
            </strong>
          </div>
        </div>
      </v-card>

      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept=".csv"
        @change="onFileChanged($event)"
      />
    </v-form>
  </CustomDialog>
</template>

<script>
import { importUsers, getCounters } from "@/services/counters";
import { handleAlert } from "@/utils";
import CustomDialog from "@/components/customDialog/CustomDialog.vue";

export default {
  name: "ImportUsersDialog",

  components: {
    CustomDialog,
  },

  data() {
    return {
      mode: "create",
      valid: false,
      loading: false,
      counters: [],
      form: {
        counter: "",
        file: null,
      },
    };
  },

  computed: {
    _title() {
      return "Importar Usuários";
    },

    _subtitle() {
      return "Selecione o arquivo csv com a listagem dos usuários que deseja importar, o arquivo deve ter uma única coluna com título 'email', e logo abaixo a listagem dos e-mails.";
    },

    _buttons() {
      return [
        {
          label: "Cancelar",
          color: "secondary",
          action: "close",
        },
        {
          label: "Importar",
          color: "primary",
          action: "submit",
        },
      ];
    },

    _rules() {
      return [(v) => (!!v && v.length > 0) || "Campo obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        let formData = new FormData();
        formData.append("file", this.form.file);

        await importUsers(this.form.counter, formData);

        this.handleAlert("Usuários importados com sucesso", "success");
        this.closeDialog();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async getCounters() {
      try {
        const payload = {
          limit: 100,
          offset: 0,
        };

        const { data } = await getCounters(payload);

        this.counters = data.map((el) => ({
          label: el.name,
          value: el._id,
        }));
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    selectFiles() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    onFileChanged(event) {
      const files = Array.from(event.target.files);

      if (files?.length) this.form.file = files[0];
    },

    openDialog() {
      this.getCounters();
      this.resetForm();
      this.$refs.customDialog.open();
    },

    closeDialog() {
      this.resetForm();
      this.$refs.customDialog.close();
    },

    resetForm() {
      this.form.file = null;
      this.form.counter = "";

      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" />
