import store from "@/store";
import { io } from "socket.io-client";

const socket = io(process.env.VUE_APP_SOCKET_URL, {
  auth: {
    token: store.getters.accessToken,
    xApiKey: process.env.VUE_APP_API_KEY,
  },
});

// Defina as ações que deseja realizar quando eventos são recebidos do servidor
socket.on("connect", () => {
  console.log("socket connected");
});

socket.on("disconnect", () => {
  console.log("Desconectado do servidor Socket.IO!");
});

// Adicione mais listeners para outros eventos que o servidor Socket.IO pode emitir

export default socket;
