import api from "./index";

export async function createJurisdictions(body) {
  return api.post(`/comarcas`, body);
}
export async function updateJurisdictions(id, body) {
  return api.put(`/comarcas/${id}`, body);
}

export async function getJurisdictions(params) {
  return api.get(`/comarcas`, { params: params });
}

export async function deleteJurisdiction(id) {
  return api.delete(`/comarcas/${id}`);
}
