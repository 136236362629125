import socket from "./index";
import { eventBus } from "@/eventBus.js";

export async function startAttendance(data, callback) {
  socket.emit("attendance:start", data, callback);
}

export async function attendanceNewMessage(data, callback) {
  socket.emit("attendance-messages:new", data, callback);
}

export async function attendanceFinish(data, callback) {
  socket.emit("attendance:finish", data, callback);
}

export async function attendanceJoin(data, callback) {
  socket.emit("attendance:join", data, callback);
}

export async function transferAttendance(data, callback) {
  socket.emit("attendance:transfer", data, callback);
}

socket.on("attendance:finished", (id) => {
  eventBus.$emit("attendance:finished", id);
});

socket.on("attendance:queue", (data) => {
  eventBus.$emit("attendance:queue", data);
});

socket.on("attendance-messages", (data) => {
  eventBus.$emit("attendance-messages", data);
});

socket.on("attendance:new", (data) => {
  eventBus.$emit("attendance:new", data);
});
socket.on("attendance:started", (data) => {
  eventBus.$emit("attendance:started", data);
});
