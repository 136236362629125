<template>
  <CustomDialog
    ref="customDialog"
    :title="_title"
    :subtitle="_subtitle"
    :footerButtons="_buttons"
    :loading="loading"
    @submit="submit()"
  >
    <v-form v-model="valid" ref="form" class="mt-4" :disabled="loading">
      <v-row>
        <v-col cols="12" md="6" class="pr-md-2">
          <v-text-field
            v-model="form.email"
            label="E-mail"
            :disabled="_editMode || _viewMode"
            :rules="_rules"
            outlined
          />
        </v-col>

        <v-col cols="12" md="6" class="pl-md-2">
          <v-select
            v-model="form.type"
            item-text="label"
            item-value="value"
            label="Função"
            :items="items"
            :disabled="_viewMode"
            :rules="_rules"
            single-line
            outlined
          />
        </v-col>
      </v-row>
    </v-form>
  </CustomDialog>
</template>

<script>
import { createUser, updateUser } from "@/services/users";
import { handleAlert } from "@/utils";
import CustomDialog from "@/components/customDialog/CustomDialog.vue";

export default {
  name: "UserDialog",

  components: {
    CustomDialog,
  },

  data() {
    return {
      mode: "create",
      valid: false,
      loading: false,
      items: [
        { label: "Administrador", value: "director" },
        { label: "Atendente", value: "attendant" },
        { label: "Gestor", value: "coordinator" },
      ],
      form: {
        _id: "",
        email: "",
        type: "",
      },
    };
  },

  computed: {
    _viewMode() {
      return this.mode === "view";
    },

    _editMode() {
      return this.mode === "edit";
    },

    _title() {
      if (this._viewMode) return "Visualizar Usuário";
      else if (this._editMode) return "Editar Usuário";

      return "Adicionar Usuário";
    },

    _subtitle() {
      if (this._viewMode) return "";
      if (this._editMode)
        return "Preencha os campos abaixo para editar usuário";

      return "Preencha os campos abaixo para cadastrar usuário";
    },

    _buttons() {
      if (this._viewMode)
        return [
          {
            label: "Fechar",
            color: "primary",
            action: "close",
          },
        ];

      return [
        {
          label: "Cancelar",
          color: "secondary",
          action: "close",
        },
        {
          label: "Prosseguir",
          color: "primary",
          action: "submit",
        },
      ];
    },

    _rules() {
      return [(v) => (!!v && v.length > 0) || "Campo obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      if (this._editMode) await this.updateUser();
      else await this.createUser();

      this.$emit("success");
      this.loading = false;

      this.closeDialog();
    },

    async createUser() {
      try {
        const payload = {
          type: this.form.type,
          email: this.form.email,
        };

        await createUser(payload);

        this.handleAlert("Usuário criado com sucesso", "success");
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async updateUser() {
      try {
        const payload = {
          type: this.form.type,
        };

        await updateUser(this.form._id, payload);

        this.handleAlert("Usuário editado com sucesso", "success");
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    openDialog(data, mode) {
      this.mode = mode || "create";

      if (data) {
        this.form = {
          _id: data._id,
          email: data.email,
          type: data.type,
        };
      } else {
        this.resetForm();
      }

      this.$refs.customDialog.open();
    },

    closeDialog() {
      this.resetForm();
      this.$refs.customDialog.close();
    },

    resetForm() {
      this.form._id = "";
      this.form.email = "";
      this.form.type = "";

      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" />
