<template>
  <CustomDialog
    ref="customDialog"
    :title="_title"
    :subtitle="_subtitle"
    :footerButtons="_buttons"
    :loading="loading"
    @submit="submit()"
  >
    <v-form v-model="valid" ref="form" class="mt-4">
      <v-text-field
        v-model="form.start.time"
        v-mask="'##:##'"
        placeholder="HH:MM"
        label="Horário de abertura"
        prepend-inner-icon="mdi-clock-time-four-outline"
        :rules="_timeRules"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="form.end.time"
        v-mask="'##:##'"
        placeholder="HH:MM"
        label="Horário de fechamento"
        prepend-inner-icon="mdi-clock-time-four-outline"
        :rules="_timeRules"
        outlined
      ></v-text-field>

      <div>Defina os dias da semana</div>

      <v-chip-group
        v-model="form.recurrence"
        color="primary"
        column
        multiple
        mandatory
      >
        <v-chip v-for="(item, i) in weekDays" :key="i" filter outlined>
          {{ item.label }}
        </v-chip>
      </v-chip-group>
    </v-form>
  </CustomDialog>
</template>

<script>
import { createEvent, updateEvent } from "@/services/calendars";
import { handleAlert } from "@/utils";
import { mask } from "vue-the-mask";
import CustomDialog from "@/components/customDialog/CustomDialog.vue";
const moment = require("moment");

export default {
  name: "EventDialog",

  components: {
    CustomDialog,
  },

  directives: {
    mask,
  },

  data() {
    return {
      mode: "create",
      valid: false,
      loading: false,
      weekDays: [
        {
          label: "Domingo",
          value: "SU",
        },
        {
          label: "Segunda",
          value: "MO",
        },
        {
          label: "Terça",
          value: "TU",
        },
        {
          label: "Quarta",
          value: "WE",
        },
        {
          label: "Quinta",
          value: "TH",
        },
        {
          label: "Sexta",
          value: "FR",
        },
        {
          label: "Sábado",
          value: "SA",
        },
      ],
      form: {
        id: "",
        summary: "",
        start: {
          time: "",
          date: "",
        },
        end: {
          time: "",
          date: "",
        },
        recurrence: [1, 2, 3, 4, 5],
      },
    };
  },

  props: {
    calendar: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    _viewMode() {
      return this.mode === "view";
    },

    _editMode() {
      return this.mode === "edit";
    },

    _title() {
      return "Horário de funcionamento";
    },

    _subtitle() {
      if (this._viewMode) return "";

      if (this._editMode)
        return "Altere os valores para editar o horário de funcionamento";

      return "Altere os valores para definir o horário de funcionamento";
    },

    _buttons() {
      if (this._viewMode)
        return [
          {
            label: "Fechar",
            color: "primary",
            action: "close",
          },
        ];

      return [
        {
          label: "Cancelar",
          color: "secondary",
          action: "close",
        },
        {
          label: "Prosseguir",
          color: "primary",
          action: "submit",
        },
      ];
    },

    _timeRules() {
      return [
        (v) => !!v || "Hora é obrigatória",
        (v) =>
          /^([01]\d|2[0-3]):([0-5]\d)$/.test(v) ||
          "Hora deve estar no formato HH:MM e ser válida",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      if (this._editMode) await this.updateEvent();
      else await this.createEvent();

      this.$emit("success");
      this.loading = false;

      this.closeDialog();
    },

    async createEvent() {
      try {
        const sortedDays = this.form.recurrence
          .sort((a, b) => a - b)
          .map((el) => this.weekDays[el].value);

        let startDate = moment();
        const today = startDate.day();

        if (!sortedDays.includes(this.weekDays[today].value)) {
          startDate = moment().day(today + 1);
          while (!sortedDays.includes(this.weekDays[startDate.day()].value)) {
            startDate.add(1, "day");
          }
        }

        const startDateTime = startDate.format(
          `YYYY-MM-DDT${this.form.start.time}:00-03:00`
        );
        const endDateTime = startDate.format(
          `YYYY-MM-DDT${this.form.end.time}:00-03:00`
        );

        const payload = {
          summary: "Horário de funcionamento",
          start: {
            dateTime: startDateTime,
            timeZone: this.calendar.timeZone,
          },
          end: {
            dateTime: endDateTime,
            timeZone: this.calendar.timeZone,
          },
          recurrence: ["RRULE:FREQ=WEEKLY;BYDAY=" + sortedDays.join(",")],
          timeZone: this.calendar.timeZone,
          transparency: "transparent",
        };

        await createEvent(this.calendar.id, payload);

        this.handleAlert(
          "Horário de funcionamento definido com sucesso",
          "success"
        );
      } catch (error) {
        this.handleAlert(error.message, "error");
      }
    },

    async updateEvent() {
      try {
        const payload = {
          summary: this.form.summary,
          start: {
            dateTime: moment(this.form.start.date).format(
              `YYYY-MM-DDT${this.form.start.time}:00-03:00`
            ),
            timeZone: this.calendar.timeZone,
          },
          end: {
            dateTime: moment(this.form.end.date).format(
              `YYYY-MM-DDT${this.form.end.time}:00-03:00`
            ),
            timeZone: this.calendar.timeZone,
          },
          recurrence: [
            "RRULE:FREQ=WEEKLY;BYDAY=" +
              this.form.recurrence
                .sort((a, b) => a - b)
                .map((el) => this.weekDays[el].value)
                .join(","),
          ],
          timeZone: this.calendar.timeZone,
          transparency: "transparent",
        };

        await updateEvent(this.calendar.id, this.form.id, payload);

        this.handleAlert(
          "Horário de funcionamento alterado com sucesso",
          "success"
        );
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    openDialog(data, mode) {
      this.mode = mode || "create";

      if (data) {
        const recurrence = data.recurrence[0].replace(
          "RRULE:FREQ=WEEKLY;BYDAY=",
          ""
        );

        this.form = {
          id: data.id,
          summary: data.summary,
          start: {
            time: moment(data.start.dateTime).format("HH:mm"),
            date: moment(data.start.dateTime).format("YYYY-MM-DD"),
          },
          end: {
            time: moment(data.end.dateTime).format("HH:mm"),
            date: moment(data.end.dateTime).format("YYYY-MM-DD"),
          },
          recurrence: recurrence
            .split(",")
            .map((el) => this.weekDays.findIndex((wd) => wd.value === el)),
        };
      } else {
        this.resetForm();
      }

      this.$refs.customDialog.open();
    },

    closeDialog() {
      this.resetForm();
      this.$refs.customDialog.close();
    },

    resetForm() {
      this.form.id = "";
      this.form.summary = "";
      this.form.start = { time: "", date: "" };
      this.form.end = { time: "", date: "" };
      this.form.recurrence = [1, 2, 3, 4, 5];

      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" />
