import api from "./index";

export async function createCounters(params, body) {
  return api.post(`/desks`, body, { params });
}

export async function updateCounters(id, body) {
  return api.put(`/desks/${id}`, body);
}

export async function getCounters(params) {
  return api.get(`/desks`, { params: params });
}

export async function deleteCounters(id) {
  return api.delete(`/desks/${id}`);
}

export async function importUsers(id, body) {
  return api.post(`/desks/${id}/attendants/import`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
