import api from "./index";

export async function getCalendars(body) {
  return api.get(`/calendars`, body);
}

export async function getCalendar(calendarID) {
  return api.get(`/calendars/${calendarID}`);
}

export async function createCalendar(body) {
  return api.post(`/calendars`, body);
}

export async function updateCalendar(body) {
  return api.put(`/calendars`, body);
}

export async function createEvent(calendarID, body) {
  return api.post(`/calendars/${calendarID}/events`, body);
}

export async function updateEvent(calendarID, eventID, body) {
  console.log;
  return api.put(`/calendars/${calendarID}/events/${eventID}`, body);
}

export async function deleteEvent(calendarID, eventID) {
  return api.delete(`/calendars/${calendarID}/events/${eventID}`);
}

export async function getCalendarEvents(calendarID, params) {
  return api.get(`/calendars/${calendarID}/events`, { params });
}

export async function importCalendar(calendarID, body) {
  return api.put(`/calendars/${calendarID}/import`, body);
}
