<template>
  <v-card class="pa-1 rounded-lg" flat>
    <v-data-table
      class="transparent"
      :headers="headers"
      :items="items"
      :page.sync="page"
      :items-per-page="limit"
      :disable-sort="disableSort"
      :disable-pagination="disablePagination"
      :mobile-breakpoint="0"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tr
          v-for="(item, rowIndex) in items"
          :key="rowIndex"
          class="text-body-2"
        >
          <td
            v-for="(column, columnIndex) in headers"
            :class="{ divider: hasDivider, 'border-right': column.borderRight }"
            :key="columnIndex"
          >
            <Entity
              v-if="column.type === 'entity'"
              v-on="$listeners"
              :data="item[column.value]"
              :item="item"
              :hideAvatar="column.hideAvatar ? column.hideAvatar : false"
            />

            <Actions
              v-if="column.type === 'actions'"
              v-on="$listeners"
              :data="item[column.value]"
              :item="item"
            />

            <div v-if="column.type === 'status'">
              <v-avatar
                :size="25"
                :color="getStatusColor(item[column.value])"
                class="custom-rounded-avatar"
                rounded="0"
              />
            </div>

            <div v-if="column.type === 'slot'">
              <slot
                v-bind="{
                  item: item,
                }"
                :name="column.value"
              />
            </div>

            <span
              v-else-if="!column.type || column.type === 'text'"
              v-html="item[column.value] ? item[column.value] : '--'"
            />
          </td>
        </tr>
      </template>
    </v-data-table>

    <div
      v-if="items.length === 0"
      class="d-flex align-center justify-center text-body-2 grey--text mt-4"
    >
      Nenhum dado encontrado
    </div>

    <div
      v-if="!disablePagination"
      class="d-flex align-center justify-center mt-2"
    >
      <v-btn
        class="mr-2"
        :disabled="page === 1"
        icon
        small
        @click="handlePage('back')"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <span class="text-body-2 font-weight-bold">{{ page }}</span>

      <v-btn
        class="ml-2"
        :disabled="page >= totalPages"
        icon
        small
        @click="handlePage('next')"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import Entity from "./partials/entity/Entity";
import Actions from "./partials/actions/Actions";
import { fetchTotalPages } from "@/utils";

export default {
  components: {
    Entity,
    Actions,
  },

  data() {
    return {
      page: 1,
      totalPages: 1,
    };
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 15,
    },
    offset: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    localPagination: {
      type: Boolean,
      default: false,
    },
    hasDivider: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    total() {
      this.handleTotalPages();
    },
  },

  methods: {
    handlePage(type) {
      this.page += type === "next" ? 1 : -1;
      const payload = {
        offset: this.offset + (type === "next" ? this.limit : -this.limit),
      };

      this.$emit("change:pagination", payload);
    },

    handleTotalPages() {
      this.totalPages = fetchTotalPages(this.total, this.limit);
    },

    getStatusColor(status) {
      return status ? "primary" : "gray4";
    },

    resetPagination() {
      this.page = 1;
    },
  },

  mounted() {
    this.handleTotalPages();
  },
};
</script>

<style src="./style.scss" lang="scss" />
