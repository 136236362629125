<template>
  <v-app v-if="loaded" id="app">
    <NavDrawer
      v-if="_authenticated"
      ref="navDrawer"
      @change-status="changeStatus($event)"
      @sign-out="signOut()"
      @notification-bell="getNotification()"
    />
    <v-main class="background">
      <v-app-bar-nav-icon
        v-if="_showNavIcon"
        class="ml-2 mt-2"
        @click.stop="handleDrawer()"
      />

      <router-view />
    </v-main>
    <CustomDialog
      ref="customDialog"
      :title="redirectAttendanceDialog"
      :footerButtons="footerButtons"
      :loading="loading"
      :persistent="true"
      @submit="handleAction"
    >
      <p>
        Você tem um novo atendimento esperando, por favor inicie o
        atendimento...
      </p>
    </CustomDialog>
    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { loadSession, signOut } from "@/services/auth";
import { updateUser } from "@/services/users";
import { eventBus } from "@/eventBus.js";
import { Howl, Howler } from "howler";
import { startAttendance } from "@/socket/attendances";
import { sendMessageNotification } from "@/services/notification";
import "./messaging_init_in_sw";
import NavDrawer from "./components/navDrawer/NavDrawer";
import AlertBar from "./components/alertBar/AlertBar.vue";
import CustomDialog from "./components/customDialog/CustomDialog.vue";

export default {
  name: "App",

  components: {
    NavDrawer,
    AlertBar,
    CustomDialog,
  },

  data() {
    return {
      loaded: false,
      attendanceId: null,
      loading: false,
      needAttendance: false,
      redirectAttendanceDialog: "Nova solicitação de atendimento",
      footerButtons: [
        {
          label: "Iniciar",
          color: "primary",
          action: "submit",
        },
      ],
      notificationInterval: null, // Intervalo para chamar getNotification() periodicamente
    };
  },

  created() {
    this.handleSession();
  },

  mounted() {
    eventBus.$on("attendance:induct", () => {
      this.attendanceHandled();
    });
    eventBus.$on("attendance:started", () => {
      this.user.status = "busy";
    });
    eventBus.$on("attendance:queue", (data) => {
      this.attendanceId = data._id;

      if (data.join) {
        const { type, status } = this.user;

        if (
          (type !== "attendant" && !this.isAdminBusy) ||
          status !== "online"
        ) {
          return;
        }

        this.newAttendance();
      }

      if (data.leave || data === undefined) {
        this.attendanceHandled();
      }
    });
  },
  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
    this.$root.$on("drawer", this.handleDrawer);
  },

  beforeDestroy() {
    this.$root.$off("alert", this.handleAlert);
    this.$root.$off("drawer", this.handleDrawer);

    if (this.notificationInterval) {
      clearInterval(this.notificationInterval);
    }
  },

  computed: {
    ...mapState(["user", "isAdminBusy"]),

    _authenticated() {
      return this.$store.getters.authenticated;
    },

    _showNavIcon() {
      return (
        this._authenticated &&
        this.$route.name !== "Chat" &&
        this.$route.name !== "Details"
      );
    },
  },

  methods: {
    ...mapActions(["setUser", "setModule", "setSignOut"]),
    handleAttendanceStarted() {
      this.$refs.customDialog.close();
      this.needAttendance = false;
    },
    getNotification() {
      Howler.autoUnlock = true;
      const notificationSound = new Howl({
        src: [
          "https://storage.googleapis.com/notification-song/ringtone-1-46486.mp3",
        ],
        volume: 1,
      });
      notificationSound.play();
    },
    attendanceHandled() {
      this.$refs.customDialog.close();
      this.needAttendance = false;
    },
    async sendMessageNotification() {
      const deviceToken = localStorage.getItem("deviceToken");
      const body = {
        message: {
          token: deviceToken,
          notification: {
            title: "Novo atendimento",
            body: "Uma nova solicitação de atendimento está aguardando.",
          },
        },
      };
      if (!deviceToken) return;
      try {
        await sendMessageNotification(body);
      } catch (e) {
        console.error(e);
      }
    },

    newAttendance() {
      this.$refs.customDialog.open();
      this.needAttendance = true;
      this.startNotificationInterval();
      this.sendMessageNotification();
    },

    handleAction() {
      this.loading = true;
      this.needAttendance = false;
      startAttendance({ attendanceID: this.attendanceId }, (error, data) => {
        this.loading = false;

        if (error) {
          console.error("Error during attendance:", error);
          return;
        }

        this.navigateToChat(data._id);
        this.$refs.customDialog.close();
      });
    },
    navigateToChat(attendanceId) {
      this.$router
        .push({ path: `/attendance/chat/${attendanceId}` })
        .catch((err) => console.error("Routing error:", err));
    },
    async handleSession() {
      const token = this.$store.getters.accessToken;

      if (token) {
        try {
          const { data } = await loadSession();

          this.setUser(data);
          this.setModule(data.type);
        } catch {
          this.signOut(false);
        }
      }

      this.loaded = true;
    },

    async signOut(handleRequest = true) {
      try {
        if (handleRequest) await signOut();
        await this.setSignOut();
        this.$router.push({ path: "/auth" }).catch(() => {
          /* ignore */
        });
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    async changeStatus(event) {
      try {
        const payload = {
          status: event,
        };

        await updateUser(this.user._id, payload);
        this.handleSession();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      }
    },

    handleDrawer() {
      this.$refs.navDrawer.handleDrawer();
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },
    startNotificationInterval() {
      this.notificationInterval = setInterval(() => {
        if (this.needAttendance) {
          this.getNotification();
        } else {
          clearInterval(this.notificationInterval);
        }
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.float-drawer {
  z-index: 999999;
  top: 0.75rem !important;
  left: 0.25rem !important;
}
</style>
