<template>
  <v-dialog v-model="dialog" :max-width="width">
    <v-card class="rounded-lg pa-8" color="neutral">
      <div class="d-flex align-center">
        <img
          :src="this.user.photo"
          alt="Foto do Usuário"
          class="imgPerson rounded-lg"
        />

        <div class="d-flex flex-column ml-4">
          <div>{{ this.user.name }}</div>
          <div>{{ formatType(this.user.type) }}</div>
          <div>{{ this.user.email }}</div>
        </div>
      </div>

      <v-row justify="center" class="mt-5">
        <v-btn
          class="text-none"
          color="primary"
          depressed
          dark
          @click="close()"
        >
          Fechar
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProfileDialog",

  props: {
    width: {
      type: [String, Number],
      default: "600",
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    open() {
      this.dialog = true;
    },
    formatType(type) {
      switch (type) {
        case "director":
          return "Administrador";
        case "coordinator":
          return "Gestor";
        case "attendant":
          return "Atendente";
        default:
          return null;
      }
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
