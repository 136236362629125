import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBHd--Jw6oSffT1PtQhAyIlGaq3qZZl_WI",
  authDomain: "balcao-virtual-tre-sp.firebaseapp.com",
  projectId: "balcao-virtual-tre-sp",
  storageBucket: "balcao-virtual-tre-sp.appspot.com",
  messagingSenderId: "815606025677",
  appId: "1:815606025677:web:8791cbfffa7b88f0104884",
};
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);
function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey:
          "BAiKUE1raqt3Ca8Mo_DbODHsDewi09jDupBCIL1NtlrBqf1TiP1Dy0Uq5fZVN26VaPoq7zFl4PIWv-z2Y--xhj8",
      }).then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("deviceToken", currentToken);
        } else {
          console.log("Can not get token");
        }
      });
    } else {
      console.log("Do not have permission!");
    }
  });
}

requestPermission();
