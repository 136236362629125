<template>
  <v-menu
    v-model="menu"
    content-class="mt-2"
    :close-on-content-click="false"
    offset-y
    :min-width="$vuetify.breakpoint.smAndUp ? '25rem' : ''"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="text-none gray3--text elevation-1"
        :class="_appliedCount ? 'primary--text' : 'gray3--text'"
        :disabled="!filters.length"
      >
        <v-icon left>mdi-filter-variant</v-icon>

        Filtrar <span v-if="_appliedCount">({{ _appliedCount }})</span>

        <v-icon v-if="filters.length" right>
          {{ menu ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </template>

    <v-card class="pa-6" flat>
      <div class="text-h5 font-weight-bold mb-2">Filtros</div>

      <div class="d-flex flex-column">
        <v-row
          v-for="(item, i) in appliedFilters"
          :key="i"
          class="my-2"
          align="center"
        >
          <v-col cols="auto" class="py-1">
            <div class="text-body-1 font-weight-regular">Onde</div>
          </v-col>

          <v-col cols="8" sm="6" md="auto" class="py-1">
            <v-select
              v-model="item.filter"
              placeholder="Selecionar filtro"
              :items="filters"
              item-text="label"
              hide-details="auto"
              return-object
              outlined
              dense
            />
          </v-col>

          <v-col cols="1" sm="auto" class="py-1">
            <div class="text-body-1 font-weight-regular">É</div>
          </v-col>

          <v-col cols="7" sm="6" md="auto" class="py-1">
            <div v-if="item.filter">
              <v-select
                v-if="item.filter.type === 'select'"
                v-model="item.value"
                placeholder="Selecionar opção"
                item-text="label"
                item-value="value"
                hide-details="auto"
                :items="item.filter.items"
                outlined
                dense
                @change="applyFilters()"
              />

              <v-text-field
                v-else
                v-model="item.value"
                placeholder="Selecionar opção"
                hide-details="auto"
                outlined
                dense
                @blur="applyFilters()"
              />
            </div>

            <v-text-field
              v-else
              placeholder="Selecionar opção"
              hide-details="auto"
              outlined
              disabled
              dense
            />
          </v-col>

          <v-col cols="1" sm="auto" class="py-1">
            <v-btn
              :disabled="!item.filter && !item.value"
              icon
              small
              @click="removeFilter(i)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <div :class="{ 'mt-4': appliedFilters.length }">
          <v-btn
            class="text-none"
            color="primary"
            depressed
            small
            @click.stop.prevent="addFilter()"
          >
            <v-icon left>mdi-plus</v-icon>
            Adicionar filtro
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
      appliedFilters: [],
      appliedObject: {},
    };
  },

  props: {
    filters: {
      type: Array,
      default: () => [],
      /**
       * @property {String} label - label of input
       * @property {String} key - key of filter
       * @property {Array} items - optional: array of select options
       * * @property {String} label - label of item selection
       * * @property {String} value - value of item selection
       **/
    },
  },

  computed: {
    _appliedCount() {
      return Object.keys(this.appliedObject).length;
    },
  },

  methods: {
    applyFilters() {
      const filters = this.appliedFilters
        .filter((el) => el.filter)
        .map((el) => ({
          key: el.filter.key,
          value: el.value,
        }));

      const payload = filters.reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
      }, {});

      this.appliedObject = structuredClone(payload);

      this.$emit("apply", payload);
    },

    addFilter() {
      this.appliedFilters.push({ filter: null, value: null });
    },

    removeFilter(index) {
      this.appliedFilters.splice(index, 1);
      this.applyFilters();
    },
  },
};
</script>

<style></style>
