<template>
  <v-container>
    <div class="d-flex mb-4">
      <div class="text-h5 font-weight-bold mb-4">Usuários</div>

      <v-spacer />

      <v-btn class="text-none gray3--text" @click="handleImportUsersDialog()">
        <v-icon left>mdi-import</v-icon>
        Importar
      </v-btn>
    </div>

    <TabNavigation
      class="mb-4"
      :tabs="_tabs"
      :default="type"
      @change="handleTabs($event)"
    />

    <div class="d-flex align-center mb-4">
      <FilterSelector :filters="_filterOptions" @apply="handleFilter($event)" />

      <v-spacer />

      <div class="search-content">
        <v-text-field
          v-model="search"
          placeholder="Pesquisar Usuário"
          class="white rounded-lg mr-4"
          append-icon="mdi-magnify"
          hide-details
          outlined
          dense
          @keyup="handleSearch()"
        />
      </div>

      <v-btn class="text-none gray3--text" @click="handleUserDialog()">
        <v-icon left>mdi-plus</v-icon>
        Adicionar
      </v-btn>
    </div>

    <div
      v-if="users.length > 0"
      class="text-body-2 font-weight-bold gray1--text mb-6"
    >
      Mostrando {{ users.length }} {{ _typeOfUsers }}
    </div>
    <div v-else class="text-body-2 font-weight-bold gray1--text mb-6">
      {{ _typeOfUsers }}
    </div>

    <CustomTable
      ref="customTable"
      :items="users"
      :headers="headers"
      :offset="pagination.offset"
      :limit="pagination.limit"
      :total="pagination.total"
      :disableSort="true"
      @change:pagination="handlePagination($event)"
      @delete="handleDeleteUser($event)"
      @edit="handleUserDialog($event, 'edit')"
      @view="handleUserDialog($event, 'view')"
    />

    <user-dialog ref="modal" @success="getUsers()" />

    <DeleteUser ref="deleteUser" @success="getUsers()" />

    <ImportUsersDialog ref="importUsers" @success="getUsers()" />
  </v-container>
</template>

<script>
import { getUsers } from "@/services/users";
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";
import CustomTable from "@/components/customTable/CustomTable.vue";
import FilterSelector from "@/components/filterSelector/FilterSelector";
import DeleteUser from "./partials/deleteUser/DeleteUser.vue";
import UserDialog from "./partials/userDialog/UserDialog.vue";
import ImportUsersDialog from "./partials/importUsersDialog/ImportUsersDialog.vue";

export default {
  name: "Users",

  components: {
    TabNavigation,
    CustomTable,
    FilterSelector,
    DeleteUser,
    UserDialog,
    ImportUsersDialog,
  },

  data() {
    return {
      interval: null,
      loading: true,
      type: "",
      search: "",
      filters: {},
      pagination: {
        limit: 15,
        offset: 0,
        total: 0,
      },
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "E-mail",
          value: "email",
        },
        {
          text: "Função",
          value: "typeLabel",
        },
        {
          value: "actions",
          type: "actions",
          align: "center",
          width: "2.5rem",
        },
      ],
      users: [],
    };
  },

  beforeMount() {
    this.getUsers();
  },

  computed: {
    _tabs() {
      return [
        {
          label: "Geral",
          value: "",
        },
        {
          label: `Atendentes`,
          value: "attendant",
        },
        {
          label: `Gestores`,
          value: "coordinator",
        },
        {
          label: `Administradores`,
          value: "director",
        },
      ];
    },
    _typeOfUsers() {
      const userCount = this.users.length;

      if (userCount === 0) {
        return "Não existem resultados";
      }

      const typeMap = {
        attendant: ["atendente", "atendentes"],
        coordinator: ["gestor", "gestores"],
        director: ["administrador", "administradores"],
      };

      // Obtém os termos correspondentes para o tipo ou usa o padrão
      const [singular, plural] = typeMap[this.type] || ["pessoa", "pessoas"];
      return userCount === 1 ? singular : plural;
    },

    _filterOptions() {
      return [
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "E-mail",
          key: "email",
        },
      ];
    },
  },

  methods: {
    async getUsers() {
      try {
        this.loading = true;

        const payload = {
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          ...(this.search && { search: this.search }),
          ...(this.type && { type: this.type }),
          ...(this.filters.name && { name: this.filters.name }),
          ...(this.filters.email && { email: this.filters.email }),
        };

        const { data, headers } = await getUsers(payload);

        this.users = data.map((el) => ({
          ...el,
          typeLabel: this.handleType(el.type),
          actions: [
            {
              value: "menu",
              icon: "mdi-dots-vertical",
              menu: [
                {
                  label: "Visualizar",
                  value: "view",
                },
                {
                  label: "Editar",
                  value: "edit",
                },
                {
                  label: "Remover",
                  value: "delete",
                },
              ],
            },
          ],
        }));
        this.pagination.total = parseInt(headers["x-count"]);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async handleDeleteUser(event) {
      this.$refs.deleteUser.open(event._id);
    },

    handleTabs(event) {
      this.type = event.value;
      this.getUsers();
    },

    handleFilter(event) {
      this.filters = structuredClone(event);
      this.pagination.offset = 0;
      this.$refs.customTable.resetPagination();

      this.getUsers();
    },

    handleSearch() {
      if (this.interval) clearInterval(this.interval);

      this.interval = setTimeout(() => {
        this.getUsers();
      }, 500);
    },

    handlePagination(event) {
      this.pagination.offset = event.offset;
      this.getUsers();
    },

    handleType(value) {
      const types = {
        attendant: "Atendente",
        coordinator: "Gestor",
        director: "Administrador",
      };

      return types[value];
    },

    handleUserDialog(user, mode) {
      this.$refs.modal.openDialog(user, mode);
    },

    handleImportUsersDialog() {
      this.$refs.importUsers.openDialog();
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
