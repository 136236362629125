import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage,
  modules: ["accessToken", "refreshToken", "module", "user", "isAdminBusy"],
});

export default new Vuex.Store({
  state: {
    accessToken: "",
    refreshToken: "",
    deviceToken: "",
    module: "",
    user: null,
    newAttendance: false,
    isAdminBusy: false,
  },

  getters: {
    authenticated: ({ accessToken, refreshToken, user }) =>
      !!accessToken && !!refreshToken && !!user,
    accessToken: ({ accessToken }) => accessToken,
    refreshToken: ({ refreshToken }) => refreshToken,
    deviceToken: ({ deviceToken }) => deviceToken,
    module: ({ module }) => module,
    user: ({ user }) => user,
    isAdminBusy: ({ isAdminBusy }) => isAdminBusy,
  },

  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload;
    },
    SET_ADMIN_STATUS(state, payload) {
      state.isAdminBusy = payload;
    },
    SET_DEVICE_TOKEN(state, payload) {
      state.deviceToken = payload;
    },

    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload;
    },

    SET_MODULE(state, payload) {
      state.module = payload;
    },

    SET_ACCOUNT(state, payload) {
      state.user = payload;
    },
    SET_NEW_ATTENDANCE(state, payload) {
      state.newAttendance = payload;
    },
  },

  actions: {
    setAccessToken({ commit }, payload) {
      commit("SET_ACCESS_TOKEN", payload);
      api.defaults.headers.common["Authorization"] = payload;
    },

    setRefreshToken({ commit }, payload) {
      commit("SET_REFRESH_TOKEN", payload);
    },
    setAdminStatus({ commit }, payload) {
      console.log("adminStatus", payload);
      commit("SET_ADMIN_STATUS", payload);
    },
    setDeviceToken({ commit }, payload) {
      commit("SET_DEVICE_TOKEN", payload);
    },

    setModule({ commit }, payload) {
      commit("SET_MODULE", payload);
    },

    setUser({ commit }, payload) {
      commit("SET_ACCOUNT", payload);
    },
    setNewAttendance({ commit }, payload) {
      commit("SET_NEW_ATTENDANCE", payload);
    },

    setSignIn({ dispatch }, payload) {
      dispatch("setAccessToken", payload.accessToken);
      dispatch("setRefreshToken", payload.refreshToken);
      dispatch("setDeviceToken", payload.deviceToken);
      dispatch("setModule", payload.module);
      dispatch("setUser", payload.user);
      dispatch("setAdminStatus", payload.isAdminBusy);
    },

    setSignOut({ dispatch }) {
      dispatch("setAccessToken", "");
      dispatch("setRefreshToken", "");
      dispatch("setModule", "");
      dispatch("setUser", null);
      dispatch("setAdminStatus", false);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
