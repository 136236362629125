import moment from "moment";
moment.locale("pt-BR");

export function handleAlert(message, type, fixed, timeout) {
  this.$root.$emit("alert", { message, type, fixed, timeout });
}

export function fetchTotalPages(total, limit = 15) {
  let value = total / limit;
  if (value % 1 !== 0) value = value + 1;

  return Math.trunc(value);
}

export function formatDate(date, format = "DD/MM/YYYY HH:mm:ss") {
  if (!date) return null;
  return moment(date).format(format);
}
