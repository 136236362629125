import api from "./index";

export async function createCourts(params, body) {
  return api.post(`/varas`, body, { params });
}
export async function updateCourts(id, body) {
  return api.put(`/varas/${id}`, body);
}

export async function getCourts(params) {
  return api.get(`/varas`, { params: params });
}

export async function deleteCourt(id) {
  return api.delete(`/varas/${id}`);
}
