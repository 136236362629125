<template>
  <v-container>
    <div class="text-h5 font-weight-bold mb-4">Balcões</div>

    <TabNavigation
      class="mb-4"
      :tabs="_tabs"
      :default="tab"
      @change="handleTabs($event)"
    />

    <router-view />
  </v-container>
</template>

<script>
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";

export default {
  name: "Counters",

  components: {
    TabNavigation,
  },

  data() {
    return {
      tab: "Counters",
    };
  },

  created() {
    this.tab = this.$route.name;
  },

  computed: {
    _tabs() {
      const tabs = [
        {
          label: `Balcões`,
          value: "Counters",
        },
        {
          label: `Varas de atendimento`,
          value: "Courts",
        },
        {
          label: `Comarcas`,
          value: "Jurisdictions",
        },
      ];

      if (
        JSON.parse(localStorage.getItem("localStorage")).module ===
        "coordinator"
      ) {
        tabs.pop();
      }

      return tabs;
    },
  },

  methods: {
    handleTabs(event) {
      if (event.value === this.tab) return;

      this.tab = event.value;
      this.$router.push({ name: event.value });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
